<template>
    <div class="doclist">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>文件管理</el-breadcrumb-item>
            <el-breadcrumb-item>腾讯文件</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item>
                        <div class="filediv">
                        <input id="file-selector" type="file"/>
                            <div><i class="el-icon-upload2"></i>上传文件</div>
                        </div>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="localUrl" placeholder="请选择" @change="getBucketList">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button icon="el-icon-refresh" circle @click="getBucketList"></el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- content -->
        <div class="pro-con">
            <!-- table -->
            <el-table
            border
            v-loading="loading"
            :data="list"
            style="width: 100%">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column
                    label="文件">
                    <template slot-scope="scope">
                        <div v-if="scope.row.type=='img'">
                             <el-image
                                style="width: 100px; height: 100px"
                                :src="scope.row.url"
                                :preview-src-list="imgs"
                                fit="contain"></el-image>
                        </div>
                        <div class="link" v-else>
                            <i class="el-icon-document" />
                            <a :href="encodeURI(scope.row.url)" target="_blank" >{{scope.row.url}}</a>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="作者id">
                    <template slot-scope="scope">
                        <div class="class">{{scope.row.Owner.ID}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="LastModified"
                    label="修改时间">
                </el-table-column>
                <el-table-column
                    prop="bind_type"
                    label="类型">
                    <template slot-scope="scope">
                        <div class="class">{{scope.row.type}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                width="100"
                fixed="right"
                v-if="addTaskRule"
                label="操作">
                    <template slot-scope="scope">
                        <i class="edit el-icon-delete-solid" @click="handleDelete(scope.row.Key)"></i>
                    </template>
                </el-table-column>
            </el-table>
            <el-empty v-if="list.length<=0" description="还没有上传文件"></el-empty>
        </div>
        
    </div>
</template>
<script>
import {checkRules} from '@/utils/util.js'
var COS = require('cos-js-sdk-v5');
export default {
    data(){
        return{
            addTaskRule:checkRules(15),//文件管理权限
            loading:false,
            cos:null,
            list:[],//文件列表
            form:{},
            imgs:[],
            options:[ //在储存桶根目录中建立以下路径:1,pdf/;2,video/;3,images/
                {
                    value:"pdf/",
                    label:"pdf文件"
                },{
                    value:"video/",
                    label:"视频文件"
                },{
                    value:"images/",
                    label:"图片文件"
                },{
                    value:"",
                    label:"所有文件"
                }
            ],
            localUrl:'pdf/'
        }
    },
    mounted(){
        this.listen()
        this.getBucketList()
    },
    created(){
        this.initCos()
    },
    methods:{
        changeDir(e){
            this.localUrl = e
            this.getBucketList()
        },
        //获取文件类型
        getFileType(url){
            if(url){
                let arr = url.split('.'),
                type = arr[arr.length-1]
                //console.log(arr)
                if(type=='jpg'||type=='jpeg'||type=='png'||type=="dmp"||type=="PNG"){
                    return 'img'
                }
                return type
            }else{
                return ""
            }
            
        },
        handleDelete(key){
            let that = this
            this.$confirm(this.CONST.DEL_CONFIRM, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    that.cos.deleteObject({
                        Bucket: that.CONST.Bucket,
                        Region: that.CONST.Region,
                        Key: key,
                    }, function(err, data) {
                        console.log(err || data);
                        if(data){
                            that.$message({
                                message: '删除成功!',
                                type: 'success'
                            });
                            that.getBucketList()
                        }else{
                            that.$message({
                                message: '删除失败！',
                                type: 'warning'
                            });
                        }
                    });
                    }).catch(err=>{
                        console.log(err)
                    })
        },
        listen(){
            let that = this
            document.getElementById('file-selector').onchange = function () {
                var file = this.files[0];
                if (!file) return;
                // 分片上传文件
                that.cos.sliceUploadFile({
                    Bucket: that.CONST.Bucket,
                    Region: that.CONST.Region,
                    Key: that.localUrl+file.name,
                    Body: file,
                    onHashProgress: function (progressData) {
                        console.log('校验中', JSON.stringify(progressData));
                    },
                    onProgress: function (progressData) {
                        console.log('上传中', JSON.stringify(progressData));
                    },
                }, function (err, data) {
                    that.loading = false
                    console.log(err, data);
                    if(data){
                        //上传成功
                        that.getBucketList()
                    }else{
                        that.$message({
                            message: err.msg?err.msg:err,
                            type: 'warning'
                        });
                        //上传失败
                    }
                });

            };
        },
        initCos(){
            // 初始化实例
            let that = this
            console.log(this.API.api)
            this.cos = new COS({
                // getAuthorization 必选参数
                getAuthorization: function (options, callback) {
                    //console.log(options)
                    var url = that.API.api+'cos/'; // url替换成您自己的后端服务
                    //console.log(url)
                    var xhr = new XMLHttpRequest();
                    xhr.open('GET', url, true);
                    xhr.onload = function (e) {
                        console.log(e)
                        try {
                            var data = JSON.parse(e.target.responseText);
                            var credentials = data.credentials;
                        } catch (e) {
                            that.$message({
                                message: e.msg?e.msg:e,
                                type: 'warning'
                            });
                            console.log(e,'000')
                        }
                        if (!data || !credentials) {
                        return console.error('credentials invalid:\n' + JSON.stringify(data, null, 2))
                        }
                        //console.log(data)
                        callback({
                            TmpSecretId: credentials.tmpSecretId,
                            TmpSecretKey: credentials.tmpSecretKey,
                            SecurityToken: credentials.sessionToken,
                            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                            StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
                            ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000000
                        })
                        that.getBucketList()
                    }
                    xhr.send()
                }
            });
            

        },
        getBucketList(){
            let that = this
            that.loading=true
            this.cos.getBucket({
                Bucket: that.CONST.Bucket,
                Region: that.CONST.Region,
                Prefix: that.localUrl, 
            }, function(err, data) {
                that.loading = false
                if (data) {
                    console.log(data.Contents)
                    let imgs = []
                    data.Contents.forEach(item=>{
                        item.url = that.API.cosUrl+item.Key
                        item.type = that.getFileType(item.url)
                        if(item.type=='img'){
                            imgs.push(item.url)
                        }
                    })
                    that.list = data.Contents
                    that.imgs = imgs
                    //console.log('存储桶存在');
                } else if (err.statusCode == 403) {
                    console.log(data)
                    console.log('没有该存储桶读权限');
                    this.$message({
                        message: '没有该存储桶读权限',
                        type: 'warning'
                    });
                }
            });
        }

    }
}
</script>
<style lang="less" scoped>
.filediv{
    position: relative;
    cursor: pointer;
}
.filediv input{
    opacity: 0;
    width: 100px;
    border-radius: 5px;
    height: 35px;
    position: relative;
    right:0; 
    top:0; 
    opacity:0; 
    left: 0;
    top: 0;
    z-index: 99;
}
.filediv div{
    height: 35px;
    line-height: 35px;
    background-color: #03a9f4;
    color: #fff;
    width: 100px;
    text-align: center;
    font-size: 14px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 98;
}
.filediv:hover{
    opacity: 0.8;
}
</style>